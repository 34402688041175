import { toast } from "react-toastify";

export const handleError = ({ error, showToast = true }) => {
    const errorMessage = error.response && error.response.data
        ? error.response.data.message
        : error.message;

    if (showToast)
        toast.error(errorMessage);

    return errorMessage;
};
