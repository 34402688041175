import React, {useState} from 'react'


import { handleError } from "../helpers/error.helper";
import { mainRequest } from '../services/main.service'

import '../style/main.css'


export default function Main() {
    const [isRequested, setIsRequested] = useState(false)
    const [userAccount, setUserAccount] = useState('')
    const [isUserWinner, setIsUserWinner] = useState('')
    const [isValidate, setIsValidate] = useState(false)



    const handleRequestUserWinner = (userAccount) => {
        if (userAccount.substr(0, 2) !== '0x' || userAccount.length !== 42 ){
            setIsValidate(true)
            setIsRequested(false)
        } else {
            mainRequest.checkIfUserWinner({ userAccount: userAccount.toLowerCase() })
                .then(({data}) => {
                    setIsUserWinner(data?.isUserWinner)
                    setIsRequested(true)
                    setIsValidate(false)
                })
                .catch((error) => handleError({ error }))
        }
    }


    return (
        <main className="main bg-gradient">
        <div className="main-bg"></div>
            <div className="main-title">
                <div className="container">
                    <div className="title-items">
                        <div className="title-content">
                        <div className="title">
                            <h3>Please enter your <span>ETH wallet</span> in order to check if you are the winner of MetaShips airdrop</h3>
                            <input
                                type="text"
                                style={{outline: 'none'}}
                                className={'input_field'}
                                onChange={(e) => setUserAccount(e.target.value)}
                                maxLength='42'
                                minLength='42'
                            />
                            { isValidate ? <span className={'input-error'}>This address is not a valid ETH address</span> : <></> }
                            <input type="button" className={'cta cta__sub w-button'} value="Check" onClick={() => handleRequestUserWinner(userAccount)}/>
                        </div>
                            {
                                isRequested ?
                                    <div className="result">
                                        { isUserWinner ?
                                            <p><span className={'winner'}>Congratulations</span>, you are in the winner list. Please check your wallet for prize.</p>
                                            : <p><span className={'lost'}>Unfortunately</span> you didn't win this time. Please stay with us for following campaigns.</p>
                                        }
                                    </div>
                                    :
                                    <></>
                            }

                        </div>  
                    </div>
                </div>
            </div>
        </main>
    )
}
